<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            v-if="field.nestedRows"
            :field="field"
        >
            <div
                v-for="(row, rowIndex) in field.nestedRows"
                :key="rowIndex"
                class="formie-page__row"
            >
                <div
                    v-for="subField in row.fields"
                    :key="`${field.id}-${subField.id}`"
                    class="formie-page__row__field formie-page--subfield"
                >
                    <component
                        :is="getFormieField(`Input${subField.displayName}`)"
                        v-model="value[subField.handle]"
                        :field="subField"
                    />
                </div>
            </div>
        </component>
    </div>
</template>

<script setup>
defineProps({
    field: {
        type: Object,
        required: true,
    }
});

const value = defineModel({
    type: [String, Object],
    default: () => ({}),
});
</script>

<style scoped>
.formie-page__row:last-child {
    margin-bottom: 0;
}
</style>
